import { useState } from 'react';

import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import MailTemplateRepository from '../repository/MailTemplateRepository';
import DataTable from '../../../components/DataTable';
import { MailTemplate } from '../types';
import { ROUTE_MAIL_TEMPLATE_OVERVIEW } from '../index';
import { formatTimestamp } from '../../../utils/common';

const MailTemplateOverview = () => {
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const repository = new MailTemplateRepository();

  const title = (
    <>
      E-mailtemplates
      {totalCount !== null && ` (${totalCount})`}
    </>
  );

  const columns = [
    {
      name: 'Naam',
      field: 'name',
      render: (mailTemplate: MailTemplate) => (
        <Link
          to={`${ROUTE_MAIL_TEMPLATE_OVERVIEW}/${mailTemplate.id}/bewerken`}
        >
          {mailTemplate.typeReadable}
        </Link>
      ),
    },
    {
      name: 'Gewijzigd',
      field: 'updated',
      render: (mailTemplate: MailTemplate) =>
        formatTimestamp(new Date(mailTemplate.updated)),
    },
  ];

  return (
    <Page title={title}>
      <DataTable
        id="mail-template-overview"
        repository={repository}
        columns={columns}
        resultCounterText={{
          singular: 'e-mailtemplate',
          plural: 'e-mailtemplates',
        }}
        onLoad={(_items, totalCount) => setTotalCount(totalCount)}
      />
    </Page>
  );
};

export default MailTemplateOverview;
