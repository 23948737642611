import React, { MouseEvent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Menu,
  Theme,
  Toolbar,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { grey, orange } from '@material-ui/core/colors';
import classnames from 'classnames';

import settings from './config/settings';
import { colors } from './config/theme';

import UserAuthenticator from './api/UserAuthenticator';
import { UserState } from './reducers/user';

import { setRoleView } from './actions';
import AppContext from './AppContext';
import {
  ROLE_VIEW_STORE_KEY,
  RoleViewInterface,
  RoleViews,
} from './RoleViewManager';

import { ReactComponent as Logo } from './assets/images/logo_only_nrz.svg';
import { ReactComponent as Wave } from './assets/images/wave.svg';

import Avatar from './modules/users/components/Avatar';
import NotificationBell from './modules/notifications/components/NotificationBell';
import UserImitator from './modules/users/domain/UserImitator';
import {
  ADMIN_CONFIG_ROUTE,
  ADMIN_MANAGEMENT_QUERY_OVERVIEW_ROUTE,
  EXAM_REPORT_OVERVIEW_ROUTE,
} from './modules/admin';
import { DECLARATIONS_BASE_ROUTE } from './modules/declarations';
import {
  DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE,
  DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE,
} from './modules/delegates';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from './modules/diploma';
import {
  EXAM_BASE_ROUTE,
  FUTURE_EXAM_OVERVIEW_ROUTE,
  PAST_EXAM_OVERVIEW_ROUTE,
} from './modules/exams';
import { ROUTE_MAIL_TEMPLATE_OVERVIEW } from './modules/mail-templates';
import {
  EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE,
  EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE,
} from './modules/examiner';
import { SWIMMING_LESSON_PROVIDER_BASE_ROUTE } from './modules/swimming-lesson-provider';
import { OPERATOR_BASE_ROUTE } from './modules/operators';
import { USER_BASE_ROUTE } from './modules/users';
import { SEPA_OVERVIEW_ROUTE } from './modules/sepa';

declare type ConfigMenuItem = {
  label: string;
  to: string;
  isActive: RegExpMatchArray | null;
  isVisible: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: '#FFF',
  },
  button: {
    alignContent: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  logo: {
    width: '88px',
    paddingRight: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    fontSize: 23,
  },
  menu: {
    width: 250,
    marginTop: 80,
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  logoLink: {
    display: 'block',
    marginTop: '64px',
  },
  menuItem: {
    textTransform: 'none',
    color: theme.palette.primary.dark,
    '&.active': {
      background: theme.palette.primary.light,
      fontWeight: 600,
    },
    '&:hover': {
      background: colors.whiteIce,
    },
  },
  inlineMenuItem: {
    marginRight: theme.spacing(3),
  },
  submenuItem: {
    fontSize: 14,
  },
  wave: {
    position: 'absolute',
    width: '100%',
    height: 150,
    transform: 'rotateX(180deg)',
    pointerEvents: 'none',
  },
  container: {
    position: 'relative',
  },
  toolbar: {
    zIndex: 1,
    height: settings.header.height,
    padding: 0,
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRight: {
    border: `1px solid ${grey[300]}`,
    borderRadius: '24px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  toolbarRightGrid: {
    width: 'auto',
    color: theme.palette.primary.dark,
  },
  profileMenu: {
    zIndex: 1401,
  },
  imitationBar: {
    width: '100%',
    height: 20,
    background: orange[800],
    textAlign: 'center',
    fontSize: 12,
  },
  userName: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    lineHeight: '18px',
    color: theme.palette.primary.dark,
    textTransform: 'none',
  },
}));

function Navigation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [accountMenuAnchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [configMenuAnchorEl, setConfigMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const { isLoggedIn, isImitating, account } = useSelector(
    (selector: { user: UserState }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: selector.user.isImitating,
      account: selector.user.account,
    }),
  );
  const { roleViewManager, localStore } = useContext(AppContext);

  const configMenuItems: ConfigMenuItem[] = [
    {
      label: 'Configuratie',
      to: ADMIN_CONFIG_ROUTE,
      isActive: location.pathname.match(`^${ADMIN_CONFIG_ROUTE}`),
      isVisible: roleViewManager.isSuperAdminView(),
    },
    {
      label: 'Gebruikers',
      to: USER_BASE_ROUTE,
      isActive: location.pathname.match(`^${USER_BASE_ROUTE}`),
      isVisible: roleViewManager.isAdminView(),
    },
    {
      label: 'Zwemlesaanbieders',
      to: SWIMMING_LESSON_PROVIDER_BASE_ROUTE,
      isActive: location.pathname.match(
        `^${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}`,
      ),
      isVisible: roleViewManager.isAdminView(),
    },
    {
      label: 'Exploitanten',
      to: OPERATOR_BASE_ROUTE,
      isActive: location.pathname.match(`^${OPERATOR_BASE_ROUTE}`),
      isVisible: roleViewManager.isAdminView(),
    },
    {
      label: 'Declaraties',
      to: DECLARATIONS_BASE_ROUTE,
      isActive: location.pathname.match(`^${DECLARATIONS_BASE_ROUTE}`),
      isVisible: roleViewManager.isAdminView(),
    },
    {
      label: 'Diplomacategorieën',
      to: DIPLOMA_CATEGORY_OVERVIEW_ROUTE,
      isActive: location.pathname.match(`^${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}`),
      isVisible: roleViewManager.isSuperAdminView(),
    },
    {
      label: 'E-mailtemplates',
      to: ROUTE_MAIL_TEMPLATE_OVERVIEW,
      isActive: location.pathname.match(`^${ROUTE_MAIL_TEMPLATE_OVERVIEW}`),
      isVisible: roleViewManager.isSuperAdminView(),
    },
    {
      label: 'Rapporten',
      to: EXAM_REPORT_OVERVIEW_ROUTE,
      isActive: location.pathname.match(`^${EXAM_REPORT_OVERVIEW_ROUTE}`),
      isVisible: roleViewManager.isSuperAdminView(),
    },
    {
      label: 'Management queries',
      to: ADMIN_MANAGEMENT_QUERY_OVERVIEW_ROUTE,
      isActive: location.pathname.match(
        `^${ADMIN_MANAGEMENT_QUERY_OVERVIEW_ROUTE}`,
      ),
      isVisible: true,
    },
    {
      label: 'SEPA-exports',
      to: SEPA_OVERVIEW_ROUTE,
      isActive: location.pathname.match(`^${SEPA_OVERVIEW_ROUTE}`),
      isVisible: roleViewManager.isSuperAdminView(),
    },
  ];

  const showConfigMenu =
    roleViewManager.isSuperAdminView() ||
    roleViewManager.isAdminView() ||
    roleViewManager.isOperatorView();

  const handleAccountMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConfigMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setConfigMenuAnchorEl(e.currentTarget);
  };

  const handleConfigMenuClose = () => {
    setConfigMenuAnchorEl(null);
  };

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();
    new UserAuthenticator(dispatch, localStore).logout(() => {
      handleAccountMenuClose();
      history.push('/');
    });
  };

  const handleMyAccount = () => {
    handleAccountMenuClose();
    history.push('/account');
  };

  const stopImitating = () => {
    new UserImitator(dispatch, localStore).stopImitating(() => {
      history.push(USER_BASE_ROUTE);
    });
  };

  const handleMyOrganization = () => {
    handleAccountMenuClose();
    history.push('/mijn-organisatie');
  };

  const handleRoleViewSwitch = (roleView: keyof RoleViewInterface) => {
    handleAccountMenuClose();
    dispatch(setRoleView(roleView));

    localStore.setItem<keyof RoleViewInterface | null>(
      ROLE_VIEW_STORE_KEY,
      roleView,
    );
  };

  const accountMenu = (
    <Menu
      anchorEl={accountMenuAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      className={classes.profileMenu}
      elevation={1}
      id="primary-account-menu"
      keepMounted
      onClose={handleAccountMenuClose}
      open={Boolean(accountMenuAnchorEl)}
      style={{ zIndex: 1401, top: 40 }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={handleMyAccount}>Mijn profiel</MenuItem>
      {!roleViewManager.isAdminView() &&
        (roleViewManager.isSwimmingLessonProviderView() ||
          roleViewManager.isOperatorView()) && (
          <MenuItem onClick={handleMyOrganization}>Mijn organisatie</MenuItem>
        )}
      {roleViewManager
        .getAvailableRoleViews(account?.roles || [])
        .map((roleView) => (
          <MenuItem
            key={roleView}
            onClick={() => handleRoleViewSwitch(roleView)}
          >
            {`Verder als ${RoleViews[roleView].label}`}
          </MenuItem>
        ))}
      {account && isImitating && (
        <MenuItem onClick={stopImitating}>Stop overnemen</MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
    </Menu>
  );

  const configMenu = (
    <Menu
      anchorEl={configMenuAnchorEl}
      id="config-menu"
      className={classes.profileMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(configMenuAnchorEl)}
      style={{ zIndex: 1401, top: 40 }}
      onClose={handleConfigMenuClose}
      elevation={1}
    >
      {configMenuItems.map((menuItem) => {
        if (!menuItem.isVisible) return null;

        return (
          <MenuItem
            key={menuItem.to}
            component={Link}
            to={menuItem.to}
            onClick={handleConfigMenuClose}
            className={classnames(classes.menuItem, classes.submenuItem, {
              active: !!menuItem.isActive,
            })}
          >
            {menuItem.label}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Wave className={classes.wave} />
        <Container className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <Link to="/" className={classes.logoLink}>
              <Logo className={classes.logo} />
            </Link>
            <Box className={classes.toolbarContent}>
              <Box className={classes.toolbarLeft}>
                {isLoggedIn && !roleViewManager.isOperatorView() && (
                  <Button
                    component={Link}
                    to="/"
                    color="inherit"
                    className={classnames(
                      classes.menuItem,
                      classes.inlineMenuItem,
                      {
                        active: location.pathname === '/',
                      },
                    )}
                  >
                    Dashboard
                  </Button>
                )}
                {roleViewManager.isExaminerView() && (
                  <>
                    <Button
                      component={Link}
                      to={EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE}
                      color="inherit"
                      className={classnames(
                        classes.menuItem,
                        classes.inlineMenuItem,
                        {
                          active: location.pathname.match(
                            `^${EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE}`,
                          ),
                        },
                      )}
                    >
                      Geplande examens
                    </Button>
                    <Button
                      component={Link}
                      to={EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE}
                      color="inherit"
                      className={classnames(
                        classes.menuItem,
                        classes.inlineMenuItem,
                        {
                          active: location.pathname.match(
                            `^${EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE}`,
                          ),
                        },
                      )}
                    >
                      Bijgewoonde examens
                    </Button>
                  </>
                )}
                {roleViewManager.isDelegateView() && (
                  <>
                    <Button
                      component={Link}
                      to={DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE}
                      color="inherit"
                      className={classnames(
                        classes.menuItem,
                        classes.inlineMenuItem,
                        {
                          active: location.pathname.match(
                            `^${DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE}`,
                          ),
                        },
                      )}
                    >
                      Bijgewoonde examens
                    </Button>
                    <Button
                      component={Link}
                      to={DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE}
                      color="inherit"
                      className={classnames(
                        classes.menuItem,
                        classes.inlineMenuItem,
                        {
                          active: location.pathname.match(
                            `^${DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE}`,
                          ),
                        },
                      )}
                    >
                      Geselecteerde examens
                    </Button>
                  </>
                )}
                {(roleViewManager.isAdminView() ||
                  roleViewManager.isSwimmingLessonProviderView() ||
                  roleViewManager.isOperatorView()) && (
                  <Button
                    component={Link}
                    to={FUTURE_EXAM_OVERVIEW_ROUTE}
                    color="inherit"
                    className={classnames(
                      classes.menuItem,
                      classes.inlineMenuItem,
                      {
                        active:
                          location.pathname.match(
                            `^${FUTURE_EXAM_OVERVIEW_ROUTE}`,
                          ) || location.pathname.match(`^${EXAM_BASE_ROUTE}`),
                      },
                    )}
                  >
                    Aangemelde examens
                  </Button>
                )}
                {(roleViewManager.isAdminView() ||
                  roleViewManager.isSwimmingLessonProviderView() ||
                  roleViewManager.isOperatorView()) && (
                  <Button
                    component={Link}
                    to={PAST_EXAM_OVERVIEW_ROUTE}
                    color="inherit"
                    className={classnames(
                      classes.menuItem,
                      classes.inlineMenuItem,
                      {
                        active: location.pathname.match(
                          `^${PAST_EXAM_OVERVIEW_ROUTE}`,
                        ),
                      },
                    )}
                  >
                    Afgelegde examens
                  </Button>
                )}
                {!roleViewManager.isSuperAdminView() &&
                  roleViewManager.isSwimmingLessonProviderManagerView() && (
                    <Button
                      component={Link}
                      to={USER_BASE_ROUTE}
                      color="inherit"
                      className={classnames(
                        classes.menuItem,
                        classes.inlineMenuItem,
                        {
                          active: location.pathname.match(
                            `^${USER_BASE_ROUTE}`,
                          ),
                        },
                      )}
                    >
                      Gebruikers
                    </Button>
                  )}
                {showConfigMenu && (
                  <Button
                    onClick={handleConfigMenuOpen}
                    className={classnames(
                      classes.menuItem,
                      classes.inlineMenuItem,
                      {
                        active:
                          configMenuItems.filter((item) => !!item.isActive)
                            .length > 0,
                      },
                    )}
                  >
                    Beheer
                  </Button>
                )}
              </Box>
              <Box className={classes.toolbarRight}>
                <Grid
                  container
                  spacing={2}
                  alignContent="center"
                  className={classes.toolbarRightGrid}
                >
                  {isLoggedIn && account && (
                    <Grid item>
                      <NotificationBell />
                    </Grid>
                  )}
                  {isLoggedIn && account && (
                    <Grid item>
                      <Button onClick={handleAccountMenuOpen} color="inherit">
                        <Avatar user={account} imitating={isImitating} />
                        <div className={classes.userName}>
                          <div>{account.fullName}</div>
                          <div>{`ingelogd als ${roleViewManager.getRoleViewName()}`}</div>
                        </div>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {accountMenu}
      {configMenu}
    </div>
  );
}

export default Navigation;
