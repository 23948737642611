import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBrowser,
  faEraser,
  faHandsHelping,
  faSave,
  faSort,
  faSortDown,
  faSortUp,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAbacus,
  faAlignLeft,
  faAward,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBicycle,
  faBook,
  faBooks,
  faCalendarAlt,
  faCamera,
  faCar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboard,
  faClipboardList,
  faClipboardListCheck,
  faCloudDownload,
  faCodeMerge,
  faCog,
  faCopy,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileAudio,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faFingerprint,
  faFlag as faFlagLight,
  faGhost,
  faGraduationCap as faGraduationCapLight,
  faGripVertical,
  faHeading,
  faImage,
  faInfoCircle,
  faLayerGroup,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faMinus,
  faMinusCircle,
  faMoneyBillWave,
  faMoneyCheckEdit,
  faPaperPlane,
  faPauseCircle,
  faPlus,
  faPlusCircle,
  faPollH,
  faRedo,
  faSearch,
  faSignature,
  faStickyNote,
  faSync,
  faText,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTools,
  faTrain,
  faTrash,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserEdit,
  faUserGraduate,
  faVideo,
  faWalking,
  faEnvelopeOpenText,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faAward as faAwardSolid,
  faChevronRight as faChevronRightSolid,
  faCircle as faCircleSolid,
  faFlag,
  faGraduationCap,
  faInfo,
  faMapMarkerAlt,
  faMinus as faMinusSolid,
  faTrash as faTrashSolid,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faBars,
  faBrowser,
  faPlus,
  faSave,
  faEdit,
  faImage,
  faTrash,
  faTrashSolid,
  faSync,
  faSearch,
  faUser,
  faUsers,
  faInfo,
  faUserCircle,
  faUserEdit,
  faCamera,
  faGhost,
  faFilter,
  faCheck,
  faEye,
  faEyeSlash,
  faTimes,
  faCircle,
  faEnvelope,
  faGraduationCap,
  faGraduationCapLight,
  faChevronRight,
  faChevronDown,
  faChevronRightSolid,
  faBook,
  faBooks,
  faCalendarAlt,
  faFlag,
  faAward,
  faFlagLight,
  faInfoCircle,
  faGripVertical,
  faMinus,
  faPollH,
  faBallotCheck,
  faText,
  faSort,
  faSortUp,
  faSortDown,
  faFileAlt,
  faCloudDownload,
  faMapMarkerAlt,
  faPaperPlane,
  faPlusCircle,
  faMinusCircle,
  faClipboardListCheck,
  faFileWord,
  faExclamationCircle,
  faFile,
  faFilePdf,
  faFileExcel,
  faFileAudio,
  faFilePowerpoint,
  faFileVideo,
  faStickyNote,
  faArrowLeft,
  faAbacus,
  faMoneyCheckEdit,
  faMoneyBillWave,
  faRedo,
  faClipboard,
  faAlignLeft,
  faTools,
  faCircleSolid,
  faBan,
  faLayerGroup,
  faFileImport,
  faFileExport,
  faFingerprint,
  faSignature,
  faEraser,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faBell,
  faCopy,
  faCodeMerge,
  faArrowRight,
  faCheckCircle,
  faPauseCircle,
  faUserGraduate,
  faCar,
  faTrain,
  faWalking,
  faBicycle,
  faVideo,
  faMinusSolid,
  faMapMarkerAltLight,
  faHandsHelping,
  faHeading,
  faFileCsv,
  faUserCheck,
  faUpload,
  faAwardSolid,
  faCog,
  faChevronLeft,
  faLongArrowLeft,
  faLongArrowRight,
  faClipboardList,
  faEnvelopeOpenText,
  faThumbsDown,
  faThumbsUp,
);
