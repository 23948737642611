// @ts-ignore
import Flow from '@flowjs/flow.js';

import { Flow as IFlow } from 'flowjs';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import {
  Address,
  ApiFilterCriteria,
  File as FileInterface,
  ValidationViolation,
} from '../types';

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function getFileURL(file: FileInterface) {
  let url = `${process.env.REACT_APP_API_URL}/api/file/${file.id}`;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    url += `?token=${urlParams.get('token')}`;
  }

  return url;
}

export function getImageURL(
  file: FileInterface,
  filter?: 'banner' | 'thumbnail' | 'icon',
) {
  if (!filter) {
    return getFileURL(file);
  }

  let url = `${process.env.REACT_APP_API_URL}/api/file/image/${file.id}/${filter}`;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    url += `?token=${urlParams.get('token')}`;
  }

  return url;
}

/**
 * Parses API filter criteria to a params object.
 *
 * @param criteria
 * @param params
 */
export function parseCriteria(
  criteria: ApiFilterCriteria | undefined,
  params: {
    [key: string]: string | number | null | boolean;
  },
) {
  if (!criteria) {
    return params;
  }

  const result: {
    [key: string]: string | number | null | boolean;
  } = {
    ...params,
    query: criteria.query || null,
  };

  if (criteria.filters) {
    Object.entries(criteria.filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.map((v) => v.value).join(',');
      } else {
        result[key] = value;
      }
    });
  }

  if (criteria.order) {
    criteria.order.forEach((item) => {
      result[`order[${item.field}]`] = item.order;
    });
  }

  return result;
}

/**
 * Create a new Flow instance for uploading files.
 */
export function createFlow(): IFlow {
  return new Flow({
    target: `${process.env.REACT_APP_API_URL}/api/file`,
    withCredentials: true,
  });
}

/**
 * Determines if validation violation exists for field.
 */
export function hasViolationForField(
  field: string,
  violations: ValidationViolation[],
) {
  return violations.find((v) => v.propertyPath === field) !== undefined;
}

/**
 * Get the current UNIX timestamp
 */
export function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}

/**
 * Export the given date value to time string.
 */
export function formatTimestamp(
  value: Date,
  dateFormat: string = 'd MMM yyyy H:mm',
) {
  return format(value, dateFormat, {
    locale: nl,
  });
}

/**
 * Export the given date value to date string.
 */
export function formatDate(
  value: Date,
  dateFormat: string = 'EEEEEE d MMM yyyy',
) {
  return format(value, dateFormat, {
    locale: nl,
  });
}

/**
 * Export the given date value to time string.
 */
export function formatTime(value: Date, timeFormat: string = 'H:mm') {
  return format(value, timeFormat, {
    locale: nl,
  });
}

/**
 * Convert the day of the week to a readable string
 *
 * @param dayOfWeek the day of the week (0 = Sunday)
 */
export function formatDayOfWeek(dayOfWeek: Number) {
  const date = new Date();

  // keep adding days until we've found a date that matches our day of the week
  while (date.getDay() !== dayOfWeek) {
    date.setDate(date.getDate() + 1);
  }

  return format(date, 'EEEE', {
    locale: nl,
  });
}

/**
 * Format an amount in cents as a string with a euro sign in front
 */
export function formatCurrency(value: number) {
  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(value / 100);
}

/**
 * Format an Address as a string.
 */
export function formatAddress(
  address: Address | null,
  separator: string = ', ',
) {
  if (!address) {
    return '';
  }

  return [
    [address.street, address.number, address.suffix].filter((e) => e).join(' '),
    [address.postalCode, address.town].join(' '),
    address.country,
  ]
    .filter((l) => l)
    .join(separator);
}

export function flowUpload(file: File, onSuccess: (id: string) => void) {
  const flow: IFlow = createFlow();
  flow.addFile(file);

  let id: string;

  flow.on('fileSuccess', (_flowFile, message) => {
    const response: FileInterface = JSON.parse(message);
    id = response.id;
  });
  flow.on('complete', () => onSuccess(id));

  flow.upload();
}
