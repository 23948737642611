import { useState } from 'react';
import { Box } from '@material-ui/core';

import NRZButton from '../../../components/Button';
import Page from '../../../components/Page';
import ExamRepository from '../../exams/repository/ExamRepository';
import ExamDashboardSection from '../../exams/components/ExamDashboardSection';
import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DELEGATE,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_LESSON_LOCATIONS,
} from '../../exams/domain/columns';
import {
  EXAM_CREATE_ROUTE,
  FUTURE_EXAM_OVERVIEW_ROUTE,
  PAST_EXAM_OVERVIEW_ROUTE,
} from '../../exams';

const Dashboard = () => {
  const [futureExamsCount, setFutureExamsCount] = useState<number | null>(null);
  const [pastExamsCount, setPastExamsCount] = useState<number | null>(null);

  const futureExamRepository = new ExamRepository(false);
  const pastExamRepository = new ExamRepository(true);

  const columns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_LESSON_LOCATIONS,
    COLUMN_EXAM_DIPLOMAS,
    COLUMN_EXAM_DELEGATE,
  ];

  return (
    <Page title="Dashboard">
      <Box mb={2}>
        <NRZButton
          label="Examen aanmelden"
          color="primary"
          variant="contained"
          icon={['fal', 'plus']}
          to={EXAM_CREATE_ROUTE}
        />
      </Box>
      <ExamDashboardSection
        id="future-exam-overview"
        title="Aangemelde examens"
        repository={futureExamRepository}
        itemCount={futureExamsCount}
        columns={columns}
        onLoad={(_items, totalCount) => setFutureExamsCount(totalCount)}
        allExamsUrl={FUTURE_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle aangemelde examens"
        canView
        canEdit
        canViewInfo={false}
      />
      <ExamDashboardSection
        id="past-exam-overview"
        title="Afgelegde examens"
        repository={pastExamRepository}
        itemCount={pastExamsCount}
        columns={columns}
        onLoad={(_items, totalCount) => setPastExamsCount(totalCount)}
        allExamsUrl={PAST_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle afgelegde examens"
        canView
        canEdit={false}
        canViewInfo={false}
      />
    </Page>
  );
};

export default Dashboard;
