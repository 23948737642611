import { Box } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import DataTable, { Column } from '../../../components/DataTable';
import { ExamReport, ExamReportField } from '../domain/types';
import Button from '../../../components/Button';
import { EXAM_REPORT_OVERVIEW_ROUTE } from '../../admin';
import SearchInput from '../../../components/search/SearchInput';
import ExamReportFieldRepository from '../repository/ExamReportFieldRepository';
import { formatTimestamp } from '../../../utils/common';

const ExamReportFieldOverview = () => {
  const { id } = useParams<{ id: string }>();
  const repository = new ExamReportFieldRepository(id);

  const columns: Column[] = [
    {
      name: '#',
      field: 'sortOrder',
      render: (field: ExamReportField) => field.sortOrder,
    },
    {
      name: 'Label',
      field: 'label',
      render: (field: ExamReportField) => (
        <Link
          to={`${EXAM_REPORT_OVERVIEW_ROUTE}/${id}/velden/${field.id}/bewerken`}
        >
          {field.label}
        </Link>
      ),
    },
    {
      name: 'Type',
      field: 'typeLabel',
    },
    {
      name: 'Aangemaakt op',
      field: 'created',
      render: (field: ExamReport) => formatTimestamp(new Date(field.created)),
    },
  ];

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Button
          icon={['fal', 'plus']}
          to={`${EXAM_REPORT_OVERVIEW_ROUTE}/${id}/velden/aanmaken`}
          label="Veld aanmaken"
        />
      </Box>
      <SearchInput />
    </Box>
  );

  return (
    <Page title="Velden" actions={actions} breadcrumbs>
      <DataTable
        id="exam-report-field-table"
        repository={repository}
        columns={columns}
      />
    </Page>
  );
};

export default ExamReportFieldOverview;
