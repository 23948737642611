import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Typography,
} from '@material-ui/core';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import SepaRepository from '../repository/SepaRepository';
import { Declaration, Sepa } from '../../../types';
import { formatCurrency } from '../../../utils/common';
import BackButton from '../../../components/BackButton';
import FormSection from '../../../components/form/FormSection';
import TextOutput from '../../../components/form/TextOutput';
import SimpleTable from '../../../components/SimpleTable';
import { DECLARATIONS_BASE_ROUTE } from '../../declarations';
import DateOutput from '../../../components/form/DateOutput';
import {
  COLUMN_DECLARATION_DELEGATE,
  COLUMN_DECLARATION_EXAM_DATE,
  COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
  COLUMN_DECLARATION_TOTAL_COMPENSATION,
  DeclarationColumn,
} from '../../declarations/domain/columns';

const SepaDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [sepa, setSepa] = useState<Sepa | null>(null);

  const sepaRepository = new SepaRepository();

  const [loaded, setLoaded] = useState<boolean>(false);

  const loadSepa = () => {
    sepaRepository.find(id).then((response) => {
      setSepa(response.data);
      setLoaded(true);
    });
  };

  useEffect(
    () => {
      loadSepa();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const title = (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1">SEPA exports</Typography>
    </Box>
  );

  const columns: DeclarationColumn[] = [
    COLUMN_DECLARATION_EXAM_DATE,
    COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
    COLUMN_DECLARATION_DELEGATE,
    COLUMN_DECLARATION_TOTAL_COMPENSATION,
    {
      name: 'Details',
      field: 'detailLink',
      render: (declaration: Declaration) => (
        <Button
          component={Link}
          to={`${DECLARATIONS_BASE_ROUTE}/${declaration.id}`}
        >
          Details
        </Button>
      ),
    },
  ];

  if (!loaded) {
    return <Loader />;
  }

  return (
    <>
      {sepa && (
        <Page title={title}>
          <FormSection title="Bestandsdetails" className="flex flex-column">
            <DateOutput label="Aangemaakt" value={sepa.created} />
            <TextOutput
              label="Totaal bedrag"
              value={formatCurrency(sepa.totalAmount)}
            />
            <TextOutput
              label="Aantal declaraties"
              value={sepa.declarations.length}
            />
            <Box>
              <FormControl>
                <FormLabel>
                  <Box>Declaraties</Box>
                </FormLabel>
                <SimpleTable items={sepa.declarations} columns={columns} />
              </FormControl>
            </Box>
          </FormSection>
        </Page>
      )}
    </>
  );
};

export default SepaDetails;
