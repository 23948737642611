import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, TextField, Theme, Typography } from '@material-ui/core';
import validator from 'validator';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';

import UserAuthenticator from './api/UserAuthenticator';
import Home from './Home';
import PasswordInput from './components/password/PasswordInput';
import ApiClient from './api/ApiClient';

type LoginState = {
  email: string;
  password: string;
  errors: { email?: boolean | undefined; password?: boolean | undefined };
  isInactive: boolean;
};

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    maxWidth: 375,
    minWidth: 375,
  },
  formTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const [state, setState] = useState<LoginState>({
    email: '',
    password: '',
    errors: {},
    isInactive: false,
  });
  const { email, password, errors, isInactive } = state;

  const handleFailure = (err: any) => {
    errors.email = true;
    errors.password = true;

    setState({
      ...state,
      errors,
      isInactive:
        err.response !== undefined
          ? err.response.data.error === 'inactive_user'
          : false,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errors.email = !validator.isEmail(email);
    errors.password = validator.isEmpty(password);

    setState({ ...state, errors });

    if (!validator.isEmail(email || '') || validator.isEmpty(password || '')) {
      return;
    }

    new UserAuthenticator(dispatch).login(
      email,
      password,
      undefined,
      handleFailure,
    );
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: e.target.value });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, password: e.target.value });
  };

  const resendActivationMail = (e: React.MouseEvent) => {
    e.preventDefault();
    ApiClient.sendActivationMail(email)
      .then(() => {
        notifications.enqueueSnackbar(
          'De activatiemail is opnieuw verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de activatiemail.',
          { variant: 'error' },
        );
      });
  };

  return (
    <Home>
      <Box className={classes.form}>
        <Typography variant="h2" className={classes.formTitle}>
          Inloggen
        </Typography>
        <form onSubmit={handleSubmit}>
          {isInactive && (
            <Alert severity="error" className={classes.error}>
              Uw account is nog niet geactiveerd.
              <Button onClick={resendActivationMail}>Opnieuw versturen.</Button>
            </Alert>
          )}
          <TextField
            type="email"
            data-testid="email"
            value={email || ''}
            onChange={changeEmail}
            error={errors.email}
            label="Gebruikersnaam"
            fullWidth
          />

          <Box mt={2} mb={3}>
            <PasswordInput
              id="password"
              data-testid="password"
              onChange={changePassword}
              error={errors.password}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="text"
              size="small"
              onClick={() => history.push('/wachtwoord-vergeten')}
            >
              Wachtwoord vergeten?
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            data-testid="submit"
          >
            Inloggen
          </Button>
        </form>
      </Box>
    </Home>
  );
};

export default Login;
