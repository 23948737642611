import { User } from '../types';
import { RoleViewInterface } from '../RoleViewManager';

export type UserState = {
  isLoggedIn: boolean;
  isImitating: boolean;
  account: User | null;
  roleView: keyof RoleViewInterface | null;
};

const defaultState: UserState = {
  isLoggedIn: false,
  isImitating: false,
  account: null,
  roleView: null,
};

const user = (
  state = defaultState,
  action: { type: string; payload?: unknown },
) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        isLoggedIn: true,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        isLoggedIn: false,
        account: null,
        roleView: null,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      };
    case 'SET_ROLE_VIEW':
      return {
        ...state,
        roleView: action.payload,
      };
    case 'SET_IMITATING':
      return {
        ...state,
        isImitating: action.payload,
      };
    case 'UPDATE_AVATAR':
      return {
        ...state,
        account: { ...state.account, avatar: action.payload },
      };
    default:
      return state;
  }
};

export default user;
