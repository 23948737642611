import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TokenResponse } from '../types';

class ApiClient {
  client: AxiosInstance;

  url: string | null;

  constructor() {
    this.client = axios.create({
      withCredentials: true,
    });
    this.url = process.env.REACT_APP_API_URL || null;
  }

  getUserFormData() {
    return this.get('/api/user-form-data');
  }

  getToken(email: string, password: string): Promise<TokenResponse> {
    return this.client.post(`${this.url}/api/token`, { email, password });
  }

  sendActivationMail(email: string) {
    return axios.post(
      `${this.url}/api/v1/account/send-activation-link`,
      { email },
      { withCredentials: true },
    );
  }

  async get(uri: string, params = {}, headers = {}) {
    return this.client.get(this.url + uri, { params, headers });
  }

  async put(uri: string, data = {}) {
    return axios.put(this.url + uri, data, { withCredentials: true });
  }

  async post(uri: string, data = {}, options: AxiosRequestConfig = {}) {
    return axios.post(this.url + uri, data, {
      ...options,
      withCredentials: true,
    });
  }

  async delete(uri: string) {
    return axios.delete(this.url + uri, { withCredentials: true });
  }
}

export default new ApiClient();
