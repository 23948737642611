import { Dispatch } from 'redux';

import ApiClient from './ApiClient';
import { loginUser, logoutUser } from '../actions';
import { ROLE_VIEW_STORE_KEY } from '../RoleViewManager';

class UserAuthenticator {
  private dispatch: Dispatch;

  private readonly localStore: LocalForage | undefined;

  constructor(dispatch: Dispatch, localStore?: LocalForage) {
    this.dispatch = dispatch;
    this.localStore = localStore;
  }

  login(
    email: string,
    password: string,
    successCallback?: (token?: string) => void,
    failureCallback?: (err?: string) => void,
  ) {
    ApiClient.getToken(email, password)
      .then((response) => {
        this.dispatch(loginUser());

        if (successCallback) {
          successCallback(response.data.token);
        }
      })
      .catch((err) => {
        if (failureCallback) {
          failureCallback(err);
        }
      });
  }

  logout(callback?: () => void) {
    ApiClient.get('/api/v1/logout').then(() => {
      this.dispatch(logoutUser());

      if (this.localStore) {
        this.localStore.removeItem(ROLE_VIEW_STORE_KEY);
      }

      if (callback) {
        callback();
      }
    });
  }
}

export default UserAuthenticator;
