import React, { useContext, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { File, ValidationViolation } from '../../../types';
import { ExamReport, ExamReportType } from '../domain/types';
import ExamReportEntryRepository from '../repository/ExamReportEntryRepository';
import fields from './fields';
import ExamReportContext from './ExamReportContext';
import FileChip from '../../../components/file/FileChip';

interface ExamReportFormProps {
  type: ExamReportType | null;
  report: ExamReport;
  examId: string;
  data: { [key: string]: { value: unknown; explanation?: string } };
  legacyPdf?: File;
  onSuccess: () => void;
}

const ExamReportForm = (props: ExamReportFormProps) => {
  const { type, report, examId, data, legacyPdf, onSuccess } = props;

  const { locked } = useContext(ExamReportContext);

  const [errors, setErrors] = useState<ValidationViolation[]>([]);

  const handleSubmit = () => {
    setErrors([]);

    if (!type) {
      return;
    }

    new ExamReportEntryRepository()
      .create(examId, {
        examReportId: report!.id,
        fields: Object.entries(data).map(([key, value]) => {
          return {
            fieldId: key,
            value: value.value as string,
            explanation: value?.explanation,
          };
        }),
      })
      .then(onSuccess)
      .catch((err) => {
        const { data } = err.response;

        if (data.detail === 'validation_failed') {
          setErrors(data.violations);
        }
      });
  };

  if (legacyPdf) {
    return (
      <Box>
        <FileChip file={legacyPdf} />
      </Box>
    );
  }

  return (
    <>
      <>
        {report.fields.map((field, idx): React.ReactElement => {
          const formField = fields.find((f) => f.type === field.type);

          if (!formField) {
            return <div />;
          }

          const Field = formField.render;
          return (
            <Box key={field.id} mb={idx === report.fields.length - 1 ? 0 : 4}>
              <Field
                field={field}
                value={data[field.id]}
                error={errors.find((error) =>
                  error.propertyPath.includes(field.id),
                )}
              />
            </Box>
          );
        })}
      </>
      <Box>
        {errors.length > 0 && (
          <Box mb={2} mt={2}>
            <Alert severity="error">
              Een aantal velden zijn niet correct ingevuld.
            </Alert>
          </Box>
        )}
      </Box>
      {!locked && (
        <Box mt={3}>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Opslaan
          </Button>
        </Box>
      )}
    </>
  );
};

export default ExamReportForm;
