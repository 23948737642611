import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@material-ui/core';

import ApiClient from '../../../api/ApiClient';
import DataTable, { Column } from '../../../components/DataTable';
import DataTableRowAction from '../../../components/DataTableRowAction';
import Page from '../../../components/Page';
import SearchContext from '../../../components/search/SearchContext';
import { Sepa } from '../../../types';
import { formatCurrency, formatTimestamp } from '../../../utils/common';
import { SEPA_OVERVIEW_ROUTE } from '../index';
import SepaRepository from '../repository/SepaRepository';

const SepaOverview = () => {
  const history = useHistory();
  const notifications = useSnackbar();
  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const sepaRepository = new SepaRepository();

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns: Column[] = [
    {
      name: 'Aangemaakt',
      field: 'created',
      render: (sepa: Sepa) => formatTimestamp(new Date(sepa.created)),
    },
    {
      name: 'Totaalbedrag',
      field: 'totalAmount',
      render: (sepa: Sepa) => formatCurrency(sepa.totalAmount),
    },
    {
      name: 'Aantal declaraties',
      field: 'numberOfDeclarations',
      render: (sepa: Sepa) => sepa.declarations.length,
    },
  ];

  const createSepa = () => {
    ApiClient.post(`/api/v1/sepa/create`)
      .then(() => {
        history.go(0);
        notifications.enqueueSnackbar(
          'Er is een nieuw SEPA-bestand aangemaakt',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er kon geen nieuw SEPA-bestand aangemaakt worden',
          { variant: 'error' },
        );
      });
  };

  const handleRowClick = (sepa: Sepa) =>
    history.push(`${SEPA_OVERVIEW_ROUTE}/${sepa.id}`);

  const title = (
    <>
      {totalCount !== null && `Sepa (${totalCount})`}
      {totalCount === null && 'Sepa'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="justify-center">
      <IconButton size="medium" onClick={createSepa}>
        <FontAwesomeIcon icon={['fal', 'file-export']} />
      </IconButton>
    </Box>
  );

  const itemActions = (sepa: Sepa) => (
    <Box>
      <DataTableRowAction
        title="Download"
        icon="file-export"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/sepa/download/${sepa.id}`;
        }}
      />
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="sepa-overview"
          repository={sepaRepository}
          columns={columns}
          actions={itemActions}
          resultCounterText={{ singular: 'sepa batch', plural: 'sepa batches' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          onRowClick={handleRowClick}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default SepaOverview;
